<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogCorrecciones"
      width="1100"
      style="z-index: 1"
    >
      <!-- Start card borrador -->
      <v-card style="border-radius: 10px; padding: 20px 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <!-- title content -->
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Detalle de orden de compra
                <span
                  style="margin-top: 12px; margin-left: 15px"
                  :class="`status-order-span status-order-background-color-${statusOrder}`"
                  >{{ statusOrder }}</span
                >
              </p>
            </v-col>
          </v-row>
          <hr style="height: 1px; background-color: #ffce00; border: none" />
          <br />
          <!-- content -->
          <v-row>
            <v-col cols="12" sm="7">
              <div>
                <!-- start item id -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">ID</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ idOrder }}
                    </p>
                  </div>
                </div>
                <!-- end item id -->
                <!-- start item proyecto -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Proyecto</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ proyecto }}
                    </p>
                  </div>
                </div>
                <!-- end item proyecto -->

                <!-- start item categorias -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Categoría</p>
                  </div>
                  <div class="status-content-restant">
                    <v-select
                      v-model="categoria"
                      class="text-Poppins"
                      item-text="sCategoryName"
                      item-value="sCategoryId"
                      :items="itemsCategory"
                      label="Selecciona la categoría"
                      style="margin-bottom: -10px"
                      @change="changeCategory"
                    ></v-select>
                    <p class="text-details-odc">
                      <!-- {{categoria}} -->
                    </p>
                  </div>
                </div>
                <!-- end item categorias -->

                <!-- start item proveedor -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Proveedor</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ proveedor }}
                    </p>
                  </div>
                </div>
                <!-- end item proveedor -->
                <!-- start item metodo de pago -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Método de pago</p>
                  </div>
                  <div class="status-content-restant">
                    <v-select
                      v-model="cmdMetodoPago"
                      class="text-Poppins"
                      item-text="sName"
                      item-value="sPaymentMethodId"
                      :items="itemsMetodoPago"
                      label="Selecciona el método de pago"
                      style="margin-bottom: -10px"
                      @change="changePaymentMethod"
                    ></v-select>
                    <p class="text-details-odc">
                      <!-- {{metodoPago}} -->
                    </p>
                  </div>
                </div>
                <!-- end item metodo de pago -->
                <!-- start item fecha de creacion -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Fecha de creación</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ fechaCreacionOrder }}
                    </p>
                  </div>
                </div>
                <!-- end item fecha de creacion -->
                <!-- start item comentarios -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Notas:</p>
                  </div>
                  <div class="status-content-restant">
                    <v-textarea
                      v-model="comentarios"
                      rows="3"
                      class="texts-inputs"
                      label="Notas al proveedor"
                    >
                    </v-textarea>
                  </div>
                </div>
                <!-- end item comentarios -->
                <!-- start item comentarios -->
                <div v-show="xsWidth" class="">
                  <div class="status-content-fixed">
                    <p class="title-details-odc"></p>
                  </div>
                  <div class="status-content-restant">
                    <v-textarea
                      v-model="comentariosInternos"
                      rows="3"
                      maxlength="255"
                      class="texts-inputs"
                      label="Notas internas"
                    >
                      <template slot="label">
                        <span>Notas internas</span>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                            <v-icon
                              style="cursor: pointer"
                              color="blue"
                              class="ml-2"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-alert-circle</v-icon
                            >

                            <!-- </v-btn> -->
                          </template>
                          <span
                            >Estas notas no serán visibles por los proveedores
                            ni estarán presentes en la cotización generada.
                          </span>
                          <br />
                          <span
                            >Solo modificables en: Borrador y
                            correcciones.</span
                          >
                        </v-tooltip>
                      </template></v-textarea
                    >
                  </div>
                </div>
                <!-- end item comentarios -->
              </div>
            </v-col>
            <v-col cols="12" sm="5">
              <div v-if="itemDetails.sQuoteKey" class="container-dashed-image">
                <div style="text-align: center; width: 100%; padding: 20px">
                  <v-icon color="#E2574C" size="100px">mdi-file-pdf</v-icon>
                  <p style="color: #a9a9a9; font-family: 'Poppins', sans-serif">
                    {{
                      filelist.length ? filelist[0].name : itemDetails.sQuoteKey
                    }}
                  </p>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                    "
                  >
                    <div class="button-wrapper">
                      <span class="label"> Reemplazar </span>
                      <input
                        type="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        ref="file"
                        accept=".pdf"
                        @change="previewFiles"
                      />
                    </div>
                    <v-btn
                      @click="openPdf"
                      elevation="0"
                      class="button-eliminar-imagen"
                    >
                      Ver documento
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="filelist.length" class="container-dashed-image">
                  <div style="text-align: center; width: 100%; padding: 20px">
                    <v-icon color="#E2574C" size="100px">mdi-file-pdf</v-icon>
                    <p
                      style="color: #a9a9a9; font-family: 'Poppins', sans-serif"
                    >
                      {{ filelist[0].name }}
                    </p>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                      "
                    >
                      <div class="button-wrapper">
                        <span class="label"> Reemplazar </span>
                        <input
                          type="file"
                          name="fields[assetsFieldHandle][]"
                          id="assetsFieldHandle"
                          class="w-px h-px opacity-0 overflow-hidden absolute"
                          ref="file"
                          accept=".pdf"
                          @change="previewFiles"
                        />
                      </div>
                      <v-btn
                        @click="openPdf"
                        elevation="0"
                        class="button-eliminar-imagen"
                      >
                        Ver documento
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    border: 2px dashed #e0e0e0;
                    width: 100%;
                    height: 230px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <div class="text-center">
                    <v-icon color="#808080">mdi-download</v-icon>
                    <div class="button-wrapper-new">
                      <span class="label"> Agregar archivo </span>

                      <input
                        type="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="previewFiles"
                        ref="file"
                        accept=".pdf"
                      />
                    </div>

                    <label for="assetsFieldHandle" class="block cursor-pointer">
                      <p style="color: #808080; margin-bottom: 0px">
                        o arrastra y suelta la cotización provista por el
                        proveedor.
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div class="container-dashed-image">
                <div style="text-align: center; width: 100%; padding: 20px">
                  <v-icon color="#E2574C" size="100px">mdi-file-pdf</v-icon>
                  <p style="color: #a9a9a9; font-family: 'Poppins', sans-serif">
                    cotizacion.pdf
                  </p>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                    "
                  >
                    <div class="button-wrapper">
                      <span class="label"> Reemplazar </span>
                      <input
                        type="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        ref="file"
                        accept=".pdf"
                        @change="previewFiles"
                      />
                    </div>
                    <v-btn
                      @click="openPdf"
                      elevation="0"
                      class="button-eliminar-imagen"
                    >
                      Ver documento
                    </v-btn>
                  </div>
                </div>
              </div> -->
              <!-- start item comentarios -->
              <div v-show="!xsWidth" class="status-content-flex mt-7">
                <v-textarea
                  v-model="comentariosInternos"
                  rows="3"
                  maxlength="255"
                  class="texts-inputs"
                  label="Notas internas"
                >
                  <template slot="label">
                    <span>Notas internas</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="cursor: pointer"
                          color="blue"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle</v-icon
                        >

                        <!-- </v-btn> -->
                      </template>
                      <span
                        >Estas notas no serán visibles por los proveedores ni
                        estarán presentes en la cotización generada.
                      </span>
                      <br />
                      <span
                        >Solo modificables en: Borrador y correcciones.</span
                      >
                    </v-tooltip>
                  </template></v-textarea
                >
              </div>
              <!-- end item comentarios -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- start item comentarios de rechazo -->
              <div class="status-content-flex">
                <p class="title-details-odc">*Comentarios de rechazo:</p>
                <span style="color: #ff7d7d; margin-left: 5px">
                  {{
                    comnetariosRechazo ? comnetariosRechazo : "Sin comentarios."
                  }}
                </span>
              </div>
              <!-- end item comentarios de rechazo -->
            </v-col>
          </v-row>
          <!-- start table materials -->
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <!-- Start simple table -->
                <v-card style="border-radius: 20px; padding: 20px !important">
                  <v-simple-table fixed-header>
                    <template>
                      <thead v-if="!bLoading">
                        <tr>
                          <th
                            class="text-center"
                            style="border-bottom: 3px solid #e4e4e4
                            width: 170px !important;
                            min-width:170px !important;
                            max-width: 170px !important;"
                          >
                            Concepto
                          </th>
                          <th
                            class="text-center"
                            style="border-bottom: 3px solid #e4e4e4
                            width: 50px !important;
                            min-width:50px !important;
                            max-width: 50px !important;"
                          >
                            Cantidad
                          </th>
                          <th
                            class="text-center"
                            style="border-bottom: 3px solid #e4e4e4
                            width: 100px !important;
                            min-width:100px !important;
                            max-width: 100px !important;"
                          >
                            Unidad de medida
                          </th>
                          <th
                            class="text-center"
                            style="border-bottom: 3px solid #e4e4e4
                            width: 100px !important;
                            min-width:100px !important;
                            max-width: 100px !important;"
                          >
                            Precio unitario
                          </th>
                          <th
                            class="text-center"
                            style="border-bottom: 3px solid #e4e4e4
                            width: 150px !important;
                            min-width:150px !important;
                            max-width: 150px !important;"
                          >
                            Importe
                          </th>
                          <th
                            class="text-center"
                            style="border-bottom: 3px solid #e4e4e4
                            width: 30px !important;
                            min-width:30px !important;
                            max-width: 30px !important;"
                          ></th>
                        </tr>
                      </thead>
                      <tbody v-if="!bLoading">
                        <tr
                          v-for="(item, index) in itemsRequestPerMaterial"
                          :key="index"
                        >
                          <td class="text-left">
                            <input
                              v-model="item.sMaterialName"
                              type="text"
                              :key="index"
                              class="input-table-materiales"
                            />
                          </td>
                          <td class="text-left">
                            <input
                              v-model="item.dQuantity"
                              type="number"
                              :key="index"
                              class="input-table-materiales"
                              min="0"
                            />
                          </td>
                          <td class="text-left">{{ item.sMeasureUnitName }}</td>
                          <td class="text-left">
                            <input
                              v-model="item.dUnitPrice"
                              type="number"
                              :key="index"
                              class="input-table-materiales"
                              min="0"
                            />
                          </td>
                          <td class="text-left">
                            ${{ sumatoriaImporte(item) }} MXN
                          </td>
                          <td>
                            <v-icon
                              color="red"
                              @click="deleteItemMaterial(index)"
                              >mdi-close</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <div v-show="bLoading">
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                        <div class="content-text-loading">
                          <p class="text-loading">
                            Cargando... Espere por favor
                          </p>
                        </div>
                      </div>
                      <br />
                      <tfoot v-if="!bLoading">
                        <tr>
                          <td>
                            <v-text-field
                              v-model="nombreMaterial"
                              class="input-nueva-soli"
                              outlined
                              dense
                              placeholder="Concepto"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="cantidadMaterial"
                              @keypress="isNumber($event)"
                              @change="notCero(cantidadMaterial)"
                              class="input-nueva-soli"
                              outlined
                              dense
                              placeholder="Cantidad"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-select
                              v-model="unidadMedidaMaterial"
                              item-text="sName"
                              item-value="sMeasureUnitId"
                              :items="itemsUnits"
                              class="input-nueva-soli"
                              background-color="#FAFAFA"
                              dense
                              outlined
                              @change="changeUnidadMedidaMaterial"
                              color="primary"
                              placeholder="Unidad de medida"
                            >
                            </v-select>
                          </td>
                          <td>
                            <v-text-field
                              v-model="precioUnitario"
                              @keypress="isNumber($event)"
                              class="input-nueva-soli"
                              outlined
                              dense
                              placeholder="Precio unitario"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-btn
                              style="
                                background-color: #ffce00;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 600;
                                border-radius: 10px;
                              "
                              :disabled="!validateAnadir"
                              @click="setMaterial"
                              >Añadir</v-btn
                            >
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <hr
                      style="
                        width: 97%;
                        border: 1px solid #fafafa;
                        margin-top: 10px;
                      "
                    />
                  </div>
                  <!-- <div>
                    <v-form>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="nombreMaterial"
                              class="input-nueva-soli"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="cantidadMaterial"
                              type="number"
                              class="input-nueva-soli"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="2">
                            <v-select
                              v-model="unidadMedidaMaterial"
                              item-text="sName"
                              item-value="sMeasureUnitId"
                              :items="itemsUnits"
                              class="custom-placeholder"
                              background-color="#FAFAFA"
                              dense
                              outlined
                              @change="changeUnidadMedidaMaterial"
                              color="primary"
                            >
                            </v-select>
                          </v-col>

                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="precioUnitario"
                              type="number"
                              class="input-nueva-soli"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="2">
                            <v-btn
                              style="
                                background-color: #ffce00;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 600;
                                border-radius: 10px;
                              "
                              :disabled="!validateAnadir"
                              @click="setMaterial"
                              >Añadir</v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div> -->
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      padding-right: 20px;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                      "
                    >
                      SUBTOTAL
                      <span style="color: #63e3b2; margin-left: 10px"
                        >${{ sumatoriaMateriales() }} MXN</span
                      >
                    </p>
                  </div>
                </v-card>
                <!-- End simple table -->
              </div>
            </v-col>
          </v-row>
          <br />
          <!-- end table materials -->
          <!-- buttons section -->
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-btn
                  x-large
                  color="black"
                  width="175"
                  outlined
                  class="text-Poppins"
                  @click="close"
                  style="
                    height: 50px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-left: 25px;
                  "
                  >Cerrar
                </v-btn>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" class="text-right">
              <div>
                <v-btn
                  x-large
                  color="primary"
                  width="100%"
                  class="text-Poppins"
                  style="
                    width: 210px;
                    height: 50px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                  "
                  :disabled="butttonGuardarAprobacion"
                  :loading="butttonGuardarAprobacion"
                  @click="createNewOrder()"
                  >Enviar para aprobación
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- End card borrador -->
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DetailsOrders",
  props: {
    openDialogCorrecciones: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  data() {
    return {
      itemsOrders: [],
      itemsProyecto: [],
      itemsCategory: [],
      itemsMetodoPago: [],
      itemsProveedores: [],
      itemsRequestPerMaterial: [],
      statusOrder: "",
      idOrder: "",
      fechaCreacionOrder: "",
      proyectoId: "",
      proyecto: "",
      categoria: "",
      proveedor: "",
      cmdMetodoPago: "",
      metodoPago: "",
      sPaymentMethodId: "",
      comentarios: "",
      comentariosInternos: "",
      subtotal: 0,
      urlDocumentPdf: "",
      inputFile: "",

      // --- variables anadir button -- //
      nombreMaterial: "",
      cantidadMaterial: "",
      unidadMedidaMaterial: "",
      precioUnitario: "",
      itemsUnits: [],
      itemsStatus: [],
      butttonGuardarBorrador: false,
      butttonGuardarAprobacion: false,
      comnetariosRechazo: "",
      xsWidth: false,
      bLoading: true,
      filelist: [], // Store our uploaded files
      sPDFName: "",
    };
  },
  methods: {
    onResize() {
      this.xsWidth = window.innerWidth > 599 ? false : true;
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 1 && evt.which < 46) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    notCero(cantidadMaterial) {
      if (cantidadMaterial !== "") {
        if (cantidadMaterial <= 0) {
          const alert = {
            color: "red",
            text: "Es necesario agregar una cantidad mayor a 0",
          };
          this.$store.commit("toggle_alert", alert);
          this.cantidadMaterial = "";
        }
      }
    },
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsStatus = resp.data.orderStatus;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    previewFiles(event) {
      this.filelist = event.target.files;
    },
    //1.- enviada
    //2.- borrador
    createNewOrder() {
      this.butttonGuardarAprobacion = true;
      var statusId = "";

      for (let i = 0; i < this.itemsStatus.length; i++) {
        const element = this.itemsStatus[i];
        if (element.sName == "Enviada") {
          statusId = element.sPurchaseOrderStatusId;
        }
      }

      const payload = {
          sComments: this.comentarios,
          sPrivateComments: this.comentariosInternos,
          aOrderMaterials: this.itemsRequestPerMaterial.map((se) => {
            return {
              dQuantity: se.dQuantity,
              sMeasureUnitId: se.sMeasureUnitId,
              sName: se.sMaterialName,
              dUnitPrice: se.dUnitPrice,
            };
          }),
          sPurchaseOrderStatusId: statusId,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };

      //post credentials and get access token from laravel backend
      db.post(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}/draft`,
        payload,
        config
      )
        .then((response) => {
          if (response.status == 201) {
            let file = this.filelist[0];

            if (file !== undefined) {
              var form = new FormData();
              form.append("file", file);

              const config2 = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("user-tk"),
                  contentType: "application/pdf",
                },
              };

              db.post(
                `${uri}/api/v1/purchase_order/${response.data.order.sPurchaseOrderId}/uploadPDF`,
                form,
                config2
              )
                .then((resp) => {
                  this.butttonGuardarAprobacion = false;
                  const alert = {
                    color: "green",
                    text: response.data.message,
                  };
                  this.close();
                  this.$store.commit("toggle_alert", alert);
                  this.$store.commit("refresher", true);
                })
                .catch((err) => {
                  this.butttonGuardarAprobacion = false;
                  const alert = {
                    color: "red",
                    text: err.response.data.message,
                  };
                  this.$store.commit("toggle_alert", alert);
                });
            } else {
              this.butttonGuardarAprobacion = false;
              const alert = {
                color: "green",
                text: response.data.message,
              };
              this.close();
              this.$store.commit("toggle_alert", alert);
              this.$store.commit("refresher", true);
            }
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.butttonGuardarBorrador = false;
          this.butttonGuardarAprobacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    close() {
      this.$emit("close");
      this.filelist = [];
    },
    allData() {
      this.getMaterialResquest();
      this.getUnits();
      this.downloadPdf();
      this.getStatus();

      this.statusOrder = this.itemDetails.PurchaseOrderStatusRecords.sName;
      this.itemsOrders = this.itemDetails;
      this.idOrder = this.itemDetails.sPurchaseOrderId;
      this.fechaCreacionOrder = this.itemDetails.tDate;
      this.proyectoId = this.itemDetails.sProjectId;
      this.proyecto = this.itemDetails.sProjectName;
      this.getCategoriesbyProyect();
      this.categoria = this.itemDetails.sCategoryId;
      this.proveedor = this.itemDetails.sSupplierName;
      this.getMetodoPago();
      this.metodoPago = this.itemDetails.sPaymentMethodId;
      this.comentarios = this.itemDetails.sComments;
      this.comentariosInternos = this.itemDetails.sPrivateComments;
      this.comnetariosRechazo =
        this.itemDetails.PurchaseOrderStatusRecords.sComments;
      this.cmdMetodoPago = this.metodoPago;
    },
    eliminarOrder() {
      var item = this.itemDetails;
      this.$store.commit("setDeleteItems", {
        id: item.sPurchaseOrderId,
        route: "/api/v1/purchase_order/" + item.sPurchaseOrderId,
        unit: "orden de compra.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    changeUnidadMedidaMaterial(e) {
      var text = this.itemsUnits.filter((val) => val.sMeasureUnitId === e);
      this.unidadMedidaMaterialText = text[0].sName;
    },
    setMaterial() {
      const arr = {
        sMaterialName: this.nombreMaterial,
        dQuantity: this.cantidadMaterial,
        sMeasureUnitId: this.unidadMedidaMaterial,
        sMeasureUnitName: this.unidadMedidaMaterialText,
        dUnitPrice: this.precioUnitario,
      };

      this.itemsRequestPerMaterial.push(arr);
      this.clearItemsMateriales();
    },
    deleteItemMaterial(e) {
      if (e !== 0) {
        this.itemsRequestPerMaterial.splice(e, 1);
      } else {
        this.bLoading = false;
        const alert = {
          color: "red",
          text: "No puede eliminar todos los materiales de la orden de compra.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    clearItemsMateriales() {
      this.nombreMaterial = "";
      this.cantidadMaterial = "";
      this.unidadMedidaMaterial = "";
      this.unidadMedidaMaterialText = "";
      this.precioUnitario = "";
    },
    openPdf() {
      if (this.filelist.length > 0) {
        this.maskedFile(this.filelist);
      } else {
        if (this.urlDocumentPdf !== "Sin documento por el momento.") {
          window.open(this.urlDocumentPdf, "_blank");
        } else {
          const alert = {
            color: "red",
            text: "No existe un documento asociado a esta orden de compra.",
          };
          this.$store.commit("toggle_alert", alert);
        }
      }
    },
    downloadPdf() {
      db.get(
        `${uri}/api/v1/purchase_order/order/${this.itemDetails.sPurchaseOrderId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          this.urlDocumentPdf = resp.data.order.sQuoteKey;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    maskedFile: function (afile) {
      var file = new Blob([afile[0]], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    getMaterialResquest() {
      this.bLoading = true;
      // get material per request
      db.get(
        `${uri}/api/v1/purchase_order/materials/${this.itemDetails.sPurchaseOrderId}/1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            // iPageNumber: "1",
            formatDate: true,
          },
        }
      )
        .then((resp) => {
          this.bLoading = false;
          this.itemsRequestPerMaterial = resp.data.OrderMaterials.map((e) => {
            return {
              ...e,
              dQuantity: Number(e.dQuantity),
            };
          });
          this.sumatoriaMateriales();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get units
    getUnits() {
      db.get(`${uri}/api/v1/measure_units`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsUnits = resp.data.units;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    sumatoriaImporte(e) {
      var sum = 0;
      sum = sum + Number(e.dQuantity) * Number(e.dUnitPrice);
      return this.mask(sum);
    },
    changeCategory() {
      const param = {
        sCategoryId: this.categoria,
        sPurchaseOrderId: this.itemDetails.sPurchaseOrderId,
      };
      db.patch(`${uri}/api/v1/purchase_order/category/`, param, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/json",
        },
      })
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changePaymentMethod(e) {
      var text = this.itemsMetodoPago.filter(
        (val) => val.sPaymentMethodId === e
      );
      this.sPaymentMethodId = text[0].sPaymentMethodId;
      const param = {
        sPaymentMethodId: this.sPaymentMethodId,
      };
      db.patch(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}`,
        param,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/json",
          },
        }
      )
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getCategoriesbyProyect() {
      db.get(`${uri}/api/v1/projects/${this.proyectoId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsCategory = resp.data.project.Categories;
          if (this.itemDetails.sCategoryId == "N/A") {
            this.itemsCategory.unshift({
              sCategoryId: "N/A",
              sCategoryName: "Sin categorizar",
            });
          }
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    onChange(event) {
      this.filelist = event.target.files;
    },
    remove(i) {
      this.filelist.splice(i, 1);
      document.getElementById("assetsFieldHandle").value = "";
    },
  },
  watch: {
    openDialogCorrecciones: function () {
      if (this.openDialogCorrecciones) {
        this.allData();
      }
    },
    closeDialogDetails: function () {
      if (!this.$store.state.refresh) {
        this.close();
      }
    },
  },
  computed: {
    closeDialogDetails() {
      return this.$store.state.refresh;
    },
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
    validateAnadir() {
      return (
        this.nombreMaterial !== "" &&
        this.cantidadMaterial !== "" &&
        this.unidadMedidaMaterial !== "" &&
        this.precioUnitario !== ""
      );
    },
  },
};
</script>

<style scoped>
.status-order-span {
  background-color: #8d8d8d;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 30px;
  position: absolute;
  margin-left: 5px;
  box-shadow: 0px 4px 8px #8d8d8d !important;
}

.status-content-flex {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.status-content-fixed {
  width: 190px;
}

.status-content-restant {
  width: calc(100% - 190px);
}

.status-order-background-color-Enviada {
  background-color: #79c6ff;
}

.status-order-background-color-Borrador {
  background-color: #7e7e7e;
}

.status-order-background-color-Aprobada,
.status-order-background-color-Recibida {
  background-color: #97e6b6;
}

.status-order-background-color-Correcciones {
  background-color: #ff7d7d;
}

.status-order-background-color-Pagada {
  background-color: #ffce00;
}

.status-order-background-color-Cancelada {
  background-color: #ff7d7d;
}

.button-eliminar-imagen {
  width: 160px;
  height: 36px;
  background-color: #ffce00 !important;
  cursor: pointer;
  color: #4e4000;
  padding: 5px 25px !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0;
}

.button-wrapper {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper span.label {
  width: 160px;
  position: relative;
  z-index: 0;
  line-height: 26px;
  display: inline-block;
  background: #ff5757;
  cursor: pointer;
  color: #ffffff;
  padding: 5px 25px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}

.button-wrapper-new {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper-new span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  background: #ffffff;
  cursor: pointer;
  color: #737373;
  padding: 5px 25px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border: 2px solid #737373;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

#assetsFieldHandle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.container-dashed-image {
  border: 2px dashed #e0e0e0;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-details-odc {
  color: #b7b7b7;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  font-weight: 400;
}

.title-details-odc {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.input-table-materiales {
  background-color: #fafafa;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 5px;
  width: 100% !important;
}

.input-table-materiales:focus {
  outline: 0;
}
</style>