<template>
  <div>
    <!-- Start card for float effect -->
    <v-card class="TableWrapper">
      <v-simple-table fixed-header height="490px">
        <template>
          <thead v-if="!bLoading">
            <tr>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                ID
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Proyecto
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Fecha de creación
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Proveedor
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Método de Pago
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Subtotal
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Estatus
              </th>
              <th
                class="text-left"
                style="border-bottom: 3px solid #e4e4e4; width: 120px"
              >
                Acción
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoading">
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-left">#{{ item.sPurchaseOrderId.slice(-5) }}</td>
              <td class="text-left">{{ item.sProjectName }}</td>
              <td class="text-left">{{ item.tDate }}</td>
              <td class="text-left">{{ item.sSupplierName }}</td>
              <td class="text-left">
                {{
                  item.sPaymentMethod == null
                    ? "indefinido"
                    : item.sPaymentMethod
                }}
              </td>
              <td class="text-left" style="white-space: nowrap">
                ${{ mask(item.dTotalPrice) }} MXN
              </td>
              <td
                style="text-align: left; font-weight: bold"
                :class="
                  `status-order-span status-order-span-color-${item.PurchaseOrderStatusRecords.sName}`
                "
              >
                {{ item.PurchaseOrderStatusRecords.sName }}
              </td>
              <td style="white-space: nowrap">
                <div>
                  <v-btn icon color="black" @click="getDetailOC(item.sPurchaseOrderId)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <!-- <v-btn icon color="black" @click="openDialogStatus(item)">
                    <v-icon>mdi-yin-yang</v-icon>
                  </v-btn> -->
                  <v-btn icon color="#FF5757" @click="deleteOrder(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
          <div v-show="bLoading">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">Cargando... Espere por favor</p>
            </div>
          </div>
        </template>
      </v-simple-table>
      <div class="footer-table">
        <div style="margin-right: 30px">
          <p>
            Filas por página:
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="footer-table-dropdown"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ iItemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeTablePagination(10)">
                  <v-list-item-title>10</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(25)">
                  <v-list-item-title>25</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(50)">
                  <v-list-item-title>50</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTablePagination(100)">
                  <v-list-item-title>100</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </p>
        </div>
        <div style="margin-right: 15px">
          <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
        </div>
        <div>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="backPageContacts"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="nextPageContacts"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>

    <!-- Start dialog borrador -->
    <BorradorDialog
      @close="openDialogBorrador = false"
      :openDialogBorrador="openDialogBorrador"
      :itemDetails="itemDetails"
    ></BorradorDialog>
    <!-- End dialog borrador -->

    <!-- Start dialog enviada -->
    <EnviadaDialog
      @close="openDialogEnviada = false"
      :openDialogEnviada="openDialogEnviada"
      :itemDetails="itemDetails"
    ></EnviadaDialog>
    <!-- End dialog enviada -->

    <!-- Start dialog aprobada -->
    <AprobadaDialog
      @close="openDialogAprobada = false"
      :openDialogAprobada="openDialogAprobada"
      :itemDetails="itemDetails"
    ></AprobadaDialog>
    <!-- End dialog aprobada -->

    <!-- Start dialog correciones -->
    <CorrecionesDialog
      @close="openDialogCorrecciones = false"
      :openDialogCorrecciones="openDialogCorrecciones"
      :itemDetails="itemDetails"
    ></CorrecionesDialog>
    <!-- End dialog correciones -->

    <!-- Start dialog pagada -->
    <PagadaDialog
      @close="openDialogPagada = false"
      :openDialogPagada="openDialogPagada"
      :itemDetails="itemDetails"
    ></PagadaDialog>
    <!-- End dialog pagada -->
  </div>
</template>
<script>
import BorradorDialog from "@/components/views/Orders/Dialogs/Borrador";
import EnviadaDialog from "@/components/views/Orders/Dialogs/Enviada";
import AprobadaDialog from "@/components/views/Orders/Dialogs/Aprobada";
import CorrecionesDialog from "@/components/views/Orders/Dialogs/Correciones";
import PagadaDialog from "@/components/views/Orders/Dialogs/Pagada";

export default {
  data() {
    return {
      bLoading: false,
      // number of pages
      iCurrentPage: 1,
      iPageNumber: 1,
      // store db items (Pickers)
      mainstatus: "En proceso",
      Estatus: ["En proceso", "Detenido", "Terminado"],
      items: [],
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      itemDetails: {},

      // --- Status dialogs --- //
      openDialogBorrador: false,
      openDialogEnviada: false,
      openDialogAprobada: false,
      openDialogCorrecciones: false,
      openDialogPagada: false,
      openDialogCancelada: false,
    };
  },
  components: {
    BorradorDialog,
    EnviadaDialog,
    AprobadaDialog,
    CorrecionesDialog,
    PagadaDialog,
  },
  mounted() {
    this.getItems();
  },
  computed: {
    // return headers from texts
    arrHeaders() {
      return this.texts.headers;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    sStatus() {
      return this.$store.state.sStatus;
    },
    sArea() {
      return this.$store.state.sArea;
    },
    token() {
      return "Bearer " + this.$store.state.token;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    sArea: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sStatus: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    endDate: function() {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getItems();
      }
    },
    startDate: function() {
      this.iCurrentPage = 1;
      this.getItems();
    },
    iCurrentPage: function() {
      this.getItems();
    },
    refreshTable: function() {
      this.getItems();
    },
    sSearchType() {
      this.getItems();
    },
    oDateRange() {
      this.iCurrentPage = 1;
      this.getItems();
    }
  },
  methods: {
    //open dialogs status
    openDialogStatus(e) {
      this.itemDetails = e;

      if (e.PurchaseOrderStatusRecords.sName == "Borrador") {
        this.openDialogBorrador = true;
      } else if (e.PurchaseOrderStatusRecords.sName == "Enviada") {
        this.openDialogEnviada = true;
      } else if (
        e.PurchaseOrderStatusRecords.sName == "Aprobada" ||
        e.PurchaseOrderStatusRecords.sName == "Recibida" ||
        e.PurchaseOrderStatusRecords.sName == "Recibido Parcial"
      ) {
        this.openDialogAprobada = true;
      } else if (e.PurchaseOrderStatusRecords.sName == "Correcciones") {
        this.openDialogCorrecciones = true;
      } else if (e.PurchaseOrderStatusRecords.sName == "Pagada") {
        this.openDialogPagada = true;
      } else if (e.PurchaseOrderStatusRecords.sName == "Cancelada") {
        this.openDialogCancelada = true;
      }
    },
    getDetailOC(sOCId) {
      this.$router.push({
        name: "OrdersAddOrDetail",
        params: { sOCId: sOCId },
      });
    },
    deleteOrder(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sPurchaseOrderId,
        route: "/api/v1/purchase_order/" + item.sPurchaseOrderId,
        unit: "orden de compra.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    changeTablePagination(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getItems();
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    // get the items from api
    getItems() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/purchase_order`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iType:this.sSearchType,
          sSearchBy: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
          sArea: this.sArea,
          sPurchaseOrderStatusId: this.sStatus,
          tStart: this.oDateRange.tStart? this.oDateRange.tStart : "",
          tEnd: this.oDateRange.tEnd? this.oDateRange.tEnd : "",
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.items = resp.data.orders;
          this.totalItems = resp.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
    oDateRange:Object,
    sSearchType:String
  },
};
</script>
<style scoped>
.actionsIcon {
  font-size: 20px !important;
}
.TableWrapper {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px !important;
  width: 100%;
  margin-bottom: 25px;
}

.fontp {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.table-contacts thead {
  background-color: #f7f8ff;
}

.table-contacts thead tr th {
  border-bottom: 1px solid #f7f8ff !important;
  color: #47567b !important;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.table-contacts tbody tr td {
  color: #250e62;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  border-bottom: 0.5px solid #979797 !important;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}

.status-order-span-color-Enviada {
  color: #79c6ff;
}

.status-order-span-color-Borrador {
  color: #7e7e7e;
}

.status-order-span-color-Aprobada,
.status-order-span-color-Recibida {
  color: #97e6b6;
}

.status-order-span-color-Correcciones {
  color: #ff7d7d;
}

.status-order-span-color-Pagada {
  color: #ffce00;
}

.status-order-span-color-Cancelada {
  color: #ff7d7d;
}
</style>
