<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogAprobada"
      width="1300"
      style="z-index: 1"
    >
      <!-- Start card borrador -->
      <v-card style="border-radius: 10px; padding: 20px 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <!-- title content -->
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Detalle de orden de compra
                <span
                  style="margin-top: 12px; margin-left: 15px"
                  :class="`status-order-span status-order-background-color-${statusOrder.replace(
                    ' ',
                    ''
                  )}`"
                  >{{ statusOrder }}</span
                >
              </p>
            </v-col>
          </v-row>
          <hr style="height: 1px; background-color: #ffce00; border: none" />
          <!-- content -->
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <!-- start item id -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">ID</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ idOrder }}
                    </p>
                  </div>
                </div>
                <!-- end item id -->
                <!-- start item proyecto -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Proyecto</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ proyecto }}
                    </p>
                  </div>
                </div>
                <!-- end item proyecto -->

                <!-- start item categorias -->

                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Categoría</p>
                  </div>
                  <div class="status-content-restant">
                    <v-select
                      v-model="categoria"
                      class="text-Poppins"
                      item-text="sCategoryName"
                      item-value="sCategoryId"
                      :items="itemsCategory"
                      label="Selecciona la categoría"
                      no-data-text="Sin información"
                      style="margin-bottom: -10px"
                      @change="changeCategory"
                    ></v-select>
                    <p class="text-details-odc">
                      <!-- {{categoria}} -->
                    </p>
                  </div>
                </div>
                <!-- end item categorias -->

                <!-- start item proveedor -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Proveedor</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ proveedor }}
                    </p>
                  </div>
                </div>
                <!-- end item proveedor -->
                <!-- start item metodo de pago -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Método de pago</p>
                  </div>
                  <div class="status-content-restant">
                    <v-select
                      v-model="cmdMetodoPago"
                      class="text-Poppins"
                      item-text="sName"
                      item-value="sPaymentMethodId"
                      :items="itemsMetodoPago"
                      label="Selecciona el método de pago"
                      no-data-text="Sin información"
                      style="margin-bottom: -10px"
                      @change="changePaymentMethod"
                    ></v-select>
                    <p class="text-details-odc">
                      <!-- {{metodoPago}} -->
                    </p>
                  </div>
                </div>
                <!-- end item metodo de pago -->
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <!-- start item fecha de creacion -->
              <div class="status-content-flex">
                <div class="status-content-fixed">
                  <p class="title-details-odc">Fecha de creación</p>
                </div>
                <div class="status-content-restant">
                  <p class="text-details-odc">
                    {{ fechaCreacionOrder }}
                  </p>
                </div>
              </div>
              <!-- end item fecha de creacion -->

              <!-- start item fecha de creacion -->
              <div class="status-content-flex">
                <div class="status-content-fixed">
                  <p class="title-details-odc">Notas al proveedor:</p>
                </div>
                <div class="status-content-restant">
                  <p class="text-details-odc">
                    {{
                      comentarios.trim() === ""
                        ? '"No hay notas adicionales para esta orden de compra".'
                        : comentarios
                    }}
                  </p>
                </div>
              </div>
              <!-- end item fecha de creacion -->

              <!-- start item fecha de creacion -->
              <div class="status-content-flex">
                <div class="status-content-fixed">
                  <p class="title-details-odc">
                    Notas internas
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                        <v-icon
                          style="
                            cursor: pointer;
                            font-size: 15px;
                            margin-bottom: 10px;
                            margin-left: 0px !important;
                          "
                          color="blue"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle</v-icon
                        >

                        <!-- </v-btn> -->
                      </template>
                      <span
                        >Estas notas no serán visibles por los proveedores ni
                        estarán presentes en la cotización generada.
                      </span>
                      <br />
                      <span
                        >Solo modificables en: Borrador y correcciones.</span
                      >
                    </v-tooltip>
                    :
                  </p>
                </div>
                <div class="status-content-restant">
                  <p class="text-details-odc">
                    {{
                      comentariosInternos.trim() === ""
                        ? '"No hay notas internas definidas para esta orden de compra."'
                        : comentariosInternos
                    }}
                  </p>
                </div>
              </div>
              <!-- end item fecha de creacion -->

              <!-- start item fecha de recepción  -->
              <div
                class="status-content-flex mt-10"
                style="white-space: nowrap"
              >
                <div class="status-content-fixed"></div>
                <div
                  v-if="statusOrder != 'Recibida'"
                  class="status-content-restant"
                  style="text-align: right"
                >
                  <v-btn
                    x-large
                    color="primary"
                    class="text-Poppins mt-2 btnConfirm"
                    style="
                      width: 180px;
                      height: 50px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                      margin: 5px;
                    "
                    v-show="!expand2"
                    @click="expand2 = !expand2"
                    >Confirmar recepción
                  </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="!expand2"
                        style="top: -30px; cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                        color="blue darken-1 "
                        >mdi-alert-circle</v-icon
                      >
                    </template>
                    <span>
                      <p class="font-weight-bold">
                        Al confirmar la recepción se da por hecho que los
                        materiales fueron recibidos correctamente y no podrán
                        ser más ya modificados.
                      </p>
                      <p>
                        Nota: En caso de que la orden de compra tenga un método
                        de pago diferente a crédito, pasará a pagado
                        automáticamente.
                      </p></span
                    >
                  </v-tooltip>

                  <v-fab-transition>
                    <div v-show="expand2">
                      <v-btn
                        @click="getMaterialCompleto"
                        class="ma-2"
                        color="light-green"
                        dark
                      >
                        Confirmar
                        <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                      </v-btn>
                      <v-btn
                        @click="expand2 = !expand2"
                        class="ma-2"
                        color="red darken-1"
                        dark
                      >
                        Cancelar
                        <v-icon dark right> mdi-cancel </v-icon>
                      </v-btn>
                    </div>
                  </v-fab-transition>
                </div>
              </div>
              <!-- end item fecha de recepción -->
            </v-col>
          </v-row>
          <!-- start table materials -->
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <!-- Start simple table -->
                <v-card style="border-radius: 20px; padding: 20px !important">
                  <v-simple-table fixed-header height="200px">
                    <template>
                      <thead v-if="!bLoading">
                        <tr>
                          <th
                            style="
                              border-bottom: 3px solid #e4e4e4;
                              text-align: center !important;
                              white-space: nowrap;
                            "
                          >
                            Recibido

                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  style="top: -10px; cursor: pointer"
                                  v-bind="attrs"
                                  v-on="on"
                                  color="blue darken-1"
                                  >mdi-alert-circle</v-icon
                                >
                              </template>
                              <span
                                >Marcar o desmarcar los materiales
                                recibidos.</span
                              >
                            </v-tooltip>
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 1px solid #e4e4e4"
                          >
                            Concepto
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Cantidad
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Cantidad recibida
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Fecha de recepción
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Unidad de medida
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Precio unitario
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Importe
                          </th>
                          <th
                            v-if="statusOrder != 'Recibida'"
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="!bLoading">
                        <tr
                          v-for="(item, index) in itemsRequestPerMaterial"
                          :key="index"
                        >
                          <td v-if="statusOrder != 'Recibida'">
                            <v-checkbox
                              style="margin-left: 20px"
                              v-model="item.bReceived"
                              :key="item.sOrderMaterialId"
                            >
                            </v-checkbox>
                          </td>
                          <td v-else>
                            <v-checkbox
                              style="margin-left: 20px"
                              v-model="item.bReceived"
                              :key="item.sOrderMaterialId"
                              disabled
                            >
                            </v-checkbox>
                          </td>
                          <td class="text-left">
                            {{ item.sMaterialName }}
                          </td>
                          <td class="text-left">
                            {{ item.dQuantity }}
                          </td>
                          <td class="text-left">
                            <input
                              v-if="statusOrder != 'Recibida'"
                              v-model="item.dReceivedAmount"
                              type="number"
                              :key="index"
                              class="input-table-materiales"
                              :max="item.dQuantity"
                              :min="0"
                              @keypress="isNumber($event)"
                              @change="validateReceivedAmount(item)"
                            />
                            <p v-else style="margin-bottom: -1px !important">
                              {{ item.dReceivedAmount }}
                            </p>
                          </td>
                          <td class="text-left">
                            <v-menu
                              v-if="statusOrder != 'Recibida'"
                              ref="bMenuFinal"
                              v-model="item.bMenuFinal"
                              :close-on-content-click="false"
                              :return-value="item.dReceivedDate"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  readonly
                                  class="custom-placeholder"
                                  background-color="#FAFAFA"
                                  dense
                                  v-model="item.dReceivedDate"
                                  outlined
                                  hide-details
                                  color="primary"
                                  append-icon="mdi-calendar"
                                  @click:clear="item.dReceivedDate = null"
                                  placeholder="Fecha recepción"
                                  persistent-hint
                                  v-on="on"
                                  style="font-family: 'Poppins', sans-serif; !important"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                color="yellow darken-2"
                                header-color="primary"
                                v-model="item.dReceivedDate"
                                no-title
                                locale="es-MX"
                                @input="item.bMenuFinal = false"
                              ></v-date-picker>
                            </v-menu>
                            <p style="margin-bottom: -1px !important" v-else>
                              {{ item.dReceivedDate }}
                            </p>
                          </td>
                          <td class="text-left">{{ item.sMeasureUnitName }}</td>
                          <td class="text-left" style="white-space: nowrap">
                            ${{ mask(item.dUnitPrice) }} MXN
                            <!-- <input
                              v-model="item.dUnitPrice"
                              type="number"
                              :key="index"
                              class="input-table-materiales"
                              min="0"
                            /> -->
                          </td>
                          <td class="text-left" style="white-space: nowrap">
                            ${{ sumatoriaImporte(item) }} MXN
                          </td>
                          <td
                            v-if="statusOrder != 'Recibida'"
                            class="text-left"
                            style="white-space: nowrap"
                          >
                            <v-btn
                              icon
                              color="light-green"
                              @click="upDateData(item)"
                            >
                              <v-icon>mdi-clipboard-check-multiple</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                      <div v-show="bLoading">
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                        <div class="content-text-loading">
                          <p class="text-loading">
                            Cargando... Espere por favor
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-simple-table>

                  <!-- <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      padding-right: 20px;
                      margin-top: 10px;
                    "
                  >
                     <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                      "
                    >
                      SUBTOTAL RECIBIDO
                      <span style="color: #63e3b2; margin-left: 10px"
                        >${{ sumatoriaMateriales() }} MXN</span
                      >
                    </p>
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                      "
                    >
                      SUBTOTAL
                      <span style="color: #63e3b2; margin-left: 10px"
                        >${{ sumatoriaMateriales() }} MXN</span
                      >
                    </p>
                  </div> -->

                  <div>
                    <v-row>
                      <v-col class="text-end" cols="6">
                        <p
                          style="
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 600;
                          "
                        >
                          SUBTOTAL RECIBIDO
                          <span style="color: #63e3b2; margin-left: 10px"
                            >${{ sumatoriaMaterialesRecibidos() }} MXN</span
                          >
                        </p>
                      </v-col>
                      <v-col class="text-end" cols="6">
                        <p
                          style="
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 600;
                          "
                        >
                          SUBTOTAL
                          <span style="color: #63e3b2; margin-left: 10px"
                            >${{ sumatoriaMateriales() }} MXN</span
                          >
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <!-- End simple table -->
              </div>
            </v-col>
          </v-row>
          <v-row v-if="statusOrder != 'Recibida'">
            <v-col cols="12" md="12">
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <!-- <v-checkbox
                  :disabled="statusOrder !== 'Aprobada'"
                  v-model="materialCompleto"
                  style="margin-top: 0px; margin-bottom: -25px"
                  label="Si ya recibió el material completo. Marque esta casilla."
                  @click="getMaterialCompleto"
                >
               
                </v-checkbox> -->
                <p>
                  Importante verificar "fechas y cantidades" de recepción de los
                  materiales.
                </p>
              </div>
            </v-col>
          </v-row>
          <br />
          <!-- end table materials -->
          <!-- buttons section -->
          <v-row>
            <v-col cols="2">
              <v-btn
                x-large
                color="black"
                width="175"
                outlined
                class="text-Poppins mt-2"
                @click="close"
                style="
                  height: 50px;
                  font-size: 13px;
                  font-weight: 600;
                  border-radius: 5px;
                "
                >Cerrar
              </v-btn>
            </v-col>
            <v-col cols="10" class="text-right">
              <v-btn
                x-large
                color="primary"
                class="text-Poppins mt-2"
                style="
                  width: 150px;
                  height: 50px;
                  color: black;
                  box-shadow: 0px 20px 40px #00000029;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 600;
                  margin: 5px;
                "
                @click="sendEmail"
                :loading="loadingSendEmail"
                :disabled="loadingSendEmail"
                >Enviar por correo
              </v-btn>
              <v-btn
                x-large
                color="primary"
                class="text-Poppins mt-2"
                style="
                  width: 200px;
                  height: 50px;
                  color: black;
                  box-shadow: 0px 20px 40px #00000029;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 600;
                  margin: 5px;
                "
                :disabled="disableBtnDownloadDoc"
                @click="downloadCotizacion"
                :loading="loadingButtonCotizacion"
                >Descargar cotización
              </v-btn>
              <v-btn
                x-large
                color="primary"
                class="text-Poppins mt-2"
                style="
                  height: 50px;
                  color: black;
                  box-shadow: 0px 20px 40px #00000029;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 600;
                  margin: 5px;
                "
                @click="downloadOrdenCompra"
                :loading="loadingButtonOrdenCompra"
                >Descargar orden de compra
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- End card borrador -->
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "DetailsOrders",
  props: {
    openDialogAprobada: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bLoading: true,
      itemsOrders: [],
      itemsProyecto: [],
      itemsCategory: [],
      itemsProveedores: [],
      itemsMetodoPago: [],
      itemsRequestPerMaterial: [],
      statusOrder: "",
      idOrder: "",
      fechaCreacionOrder: "",
      proyectoId: "",
      proyecto: "",
      categoria: "",
      proveedor: "",
      blnReceived: true,
      cmdMetodoPago: "",
      metodoPago: "",
      sPaymentMethodId: "",
      comentarios: "",
      comentariosInternos: "",
      subtotal: 0,
      document: "",
      materialCompleto: false,
      recibioMaterial: false,
      fechaRecibidoMaterial: "",
      loadingButtonCotizacion: false,
      loadingButtonOrdenCompra: false,
      loadingSendEmail: false,
      loadingConfirmReceived: false,
      selected: [],
      itemsStatus: [],
      expand2: false,
      refreshAndBack: false,
      aMonth: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      formDate: true,
      disableBtnDownloadDoc: false,
    };
  },
  methods: {
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsStatus = resp.data.orderStatus;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    sendEmail() {
      this.loadingSendEmail = true;

      db.post(
        `${uri}/api/v1/purchase_order/emailTo/${this.itemDetails.sPurchaseOrderId}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/json",
          },
        }
      )
        .then((resp) => {
          this.loadingSendEmail = false;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.loadingSendEmail = false;
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getMaterialCompleto() {
      db.put(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}/delivered`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            ContentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          this.materialCompleto = false;
          this.refreshAndBack = true;
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    downloadCotizacion() {
      this.loadingButtonCotizacion = true;

      db.get(
        `${uri}/api/v1/purchase_order/order/${this.itemDetails.sPurchaseOrderId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          if (resp.data.order.sQuoteKey !== "Sin documento por el momento.") {
            this.loadingButtonCotizacion = false;
            window.open(resp.data.order.sQuoteKey, "_blank");
          } else {
            this.loadingButtonCotizacion = false;
            const alert = {
              color: "red",
              text: "No existe un documento asociado a esta orden de compra.",
            };
            this.$store.commit("toggle_alert", alert);
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonCotizacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    downloadOrdenCompra() {
      this.loadingButtonOrdenCompra = true;

      // get material per request
      db.get(
        `${uri}/api/v1/purchase_order/pdf/${this.itemDetails.sPurchaseOrderId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          this.loadingButtonOrdenCompra = false;
          window.open(resp.data.pdfUrl, "_blank");
        })
        .catch((err) => {
          this.loadingButtonOrdenCompra = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    close() {
      this.$emit("close");
      this.bLoading = true;
      this.expand2 = false;
      this.refreshAndBack = false;
    },
    allData() {
      this.disableBtnDownloadDoc = this.itemDetails.sQuoteKey == "";
      this.statusOrder = this.itemDetails.PurchaseOrderStatusRecords.sName;
      this.itemsOrders = this.itemDetails;
      this.idOrder = this.itemDetails.sPurchaseOrderId;
      this.fechaCreacionOrder = this.itemDetails.tDate;
      this.proyectoId = this.itemDetails.sProjectId;
      this.proyecto = this.itemDetails.sProjectName;
      this.proveedor = this.itemDetails.sSupplierName;
      this.getMetodoPago();
      this.metodoPago = this.itemDetails.sPaymentMethodId;
      this.getCategoriesbyProyect();
      this.categoria = this.itemDetails.sCategoryId;
      this.comentarios = this.itemDetails.sComments;
      this.comentariosInternos = this.itemDetails.sPrivateComments;
      this.recibioMaterial = this.itemDetails.bDelivered;
      this.cmdMetodoPago = this.metodoPago;
      this.fechaRecibidoMaterial =
        this.itemDetails.PurchaseOrderStatusRecords.created_at;

      if (
        this.statusOrder == "Aprobada" ||
        this.statusOrder == "Recibido Parcial"
      ) {
        this.formDate = false;
      } else {
        this.formDate = true;
      }

      this.getMaterialResquest();
      this.getStatus();
      this.getStatusOrder();
    },
    getMaterialResquest() {
      this.bLoading = true;

      // get material per request
      db.get(
        // `${uri}/api/v1/purchase_order/materials/${this.itemDetails.sPurchaseOrderId}/1?formatDate=${formatDate}`,
        `${uri}/api/v1/purchase_order/materials/${this.itemDetails.sPurchaseOrderId}/1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            // iPageNumber: 1,
            formatDate: this.formDate,
          },
        }
      )
        .then((resp) => {
          this.bLoading = false;

          var i = 0;
          var date = moment.utc().format();
          this.itemsRequestPerMaterial = resp.data.OrderMaterials.map((e) => {
            i++;
            return {
              ...e,
              dQuantity: Number(e.dQuantity),

              // Modificar quantity de la primera condicion por la nueva variable
              dReceivedAmount:
                this.statusOrder != "Recibida"
                  ? e.dQuantityReceived != null
                    ? Number(e.dQuantityReceived)
                    : Number(0)
                  : e.dQuantityReceived != null
                  ? Number(e.dQuantityReceived)
                  : "N/A",
              // Agregar campo nuevo tipo boolean
              bReceived: e.bReceived,
              // Cambiar hardcore de campo por columna nueva de fecha de recepcion
              dReceivedDate:
                this.statusOrder != "Recibida"
                  ? e.tReceivedDate != null
                    ? e.tReceivedDate.substr(0, 11).replace("T", "")
                    : moment.utc(date).local().format("YYYY-MM-DD")
                  : e.tReceivedDate != null
                  ? e.tReceivedDate.substr(0, 11)
                  : "N/A",

              bMenuFinal: false,
            };
          });

          this.sumatoriaMateriales();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    formatDate(date) {
      date = moment(date).locale("es").format("DD/MMM/YYYY").replace(".", "");
      return date.substr(0, 3) + date.charAt(3).toUpperCase() + date.substr(4);
    },

    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    sumatoriaMaterialesRecibidos() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + Number(element.dReceivedAmount) * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    sumatoriaImporte(e) {
      var sum = 0;
      sum = sum + Number(e.dQuantity) * Number(e.dUnitPrice);
      return this.mask(sum);
    },
    changeCategory() {
      const param = {
        sCategoryId: this.categoria,
        sPurchaseOrderId: this.itemDetails.sPurchaseOrderId,
      };
      db.patch(`${uri}/api/v1/purchase_order/category/`, param, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/json",
        },
      })
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changePaymentMethod(e) {
      var text = this.itemsMetodoPago.filter(
        (val) => val.sPaymentMethodId === e
      );
      this.sPaymentMethodId = text[0].sPaymentMethodId;
      const param = {
        sPaymentMethodId: this.sPaymentMethodId,
      };

      db.patch(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}`,
        param,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/json",
          },
        }
      )
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    upDateData(item) {
      const payload = {
          // sPurchaseOrderId : this.idOrder,
          bReceived: item.bReceived,
          dQuantityReceived: parseInt(item.dReceivedAmount),
          tReceivedDate: item.dReceivedDate,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/json;charset=utf-8",
          },
        };

      if (item.dQuantity < item.dReceivedAmount) {
        const alert = {
          color: "red",
          text: "Es necesario agregar una cantidad recibida menor o igual a la cantidad.",
        };
        this.$store.commit("toggle_alert", alert);
        item.dReceivedAmount = item.dQuantity;
        return false;
      } else {
        db.put(
          `${uri}/api/v1/order_materials/${item.sOrderMaterialId}`,
          payload,
          config
        )
          .then((resp) => {
            const alert = {
              color: "green",
              text: resp.data.message,
            };
            this.$store.commit("toggle_alert", alert);
            this.getStatusOrder();
            this.$store.commit("refresher", true);
          })
          .catch((err) => {
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      }
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    // metodo para confirmar la recepcion del material
    confirmReceived() {
      this.loadingConfirmReceived = true;
      var statusId = "";

      for (let i = 0; i < this.itemsStatus.length; i++) {
        const element = this.itemsStatus[i];
        if (element.sName == "Aprobada") {
          statusId = element.sPurchaseOrderStatusId;
        }
      }

      const payload = {
          aOrderMaterials: this.itemsRequestPerMaterial.map((se) => {
            return {
              sOrderMaterialId: se.sOrderMaterialId,
              bReceived: se.bReceived,
              dQuantity: se.dQuantity,
              dReceivedAmount: parseFloat(se.dReceivedAmount),
              dReceivedDate: se.dReceivedDate,
              sMeasureUnitId: se.sMeasureUnitId,
              sName: se.sMaterialName,
              dUnitPrice: se.dUnitPrice,
            };
          }),
          sPurchaseOrderStatusId: statusId,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      this.loadingConfirmReceived = false;

      // post credentials and get access token from laravel backend
      db.post(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}/draft`,
        payload,
        config
      )
        .then((response) => {
          if (response.status == 201) {
            let file = this.inputFile[0];

            if (file !== undefined) {
              var form = new FormData();
              form.append("file", file);

              const config2 = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("user-tk"),
                  contentType: "application/pdf",
                },
              };

              db.post(
                `${uri}/api/v1/purchase_order/${response.data.order.sPurchaseOrderId}/uploadPDF`,
                form,
                config2
              )
                .then((resp) => {
                  this.butttonGuardarAprobacion = false;
                  const alert = {
                    color: "green",
                    text: response.data.message,
                  };
                  this.close();
                  this.$store.commit("toggle_alert", alert);
                  this.$store.commit("refresher", true);
                })
                .catch((err) => {
                  this.butttonGuardarAprobacion = false;
                  const alert = {
                    color: "red",
                    text: err.response.data.message,
                  };
                  this.$store.commit("toggle_alert", alert);
                });
            } else {
              this.butttonGuardarAprobacion = false;
              const alert = {
                color: "green",
                text: response.data.message,
              };
              this.close();
              this.$store.commit("toggle_alert", alert);
              this.$store.commit("refresher", true);
            }
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.butttonGuardarBorrador = false;
          this.butttonGuardarAprobacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // obtiene la fecha hora y zona
    validateReceivedAmount(item) {
      if (item.dQuantity < item.dReceivedAmount) {
        item.dReceivedAmount = item.dQuantity;

        const alert = {
          color: "red",
          text: "Es necesario agregar una cantidad recibida menor o igual a la cantidad",
        };
        this.$store.commit("toggle_alert", alert);
      } else if (item.dReceivedAmount == "") {
        item.dReceivedAmount = item.dQuantity;

        const alert = {
          color: "red",
          text: "Es necesario agregar una cantidad recibida",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    // get status of order
    getStatusOrder() {
      db.get(
        `${uri}/api/v1/purchase_order/order/${this.itemDetails.sPurchaseOrderId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          // this.itemsStatus = resp.data.orderStatus;
          this.statusOrder = resp.data.order.PurchaseOrderStatusRecords.sName;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getCategoriesbyProyect() {
      db.get(`${uri}/api/v1/projects/${this.proyectoId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsCategory = resp.data.project.Categories;
          if (this.itemDetails.sCategoryId == "N/A") {
            this.itemsCategory.unshift({
              sCategoryId: "N/A",
              sCategoryName: "Sin categorizar",
            });
          }
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  watch: {
    openDialogAprobada: function () {
      if (this.openDialogAprobada) {
        this.allData();
      }
    },
    closeDialogDetails: function () {
      if (!this.$store.state.refresh && this.refreshAndBack) {
        this.close();
      }
    },
  },
  computed: {
    closeDialogDetails() {
      return this.$store.state.refresh;
    },
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
  },
};
</script>

<style scoped>
.status-order-span {
  background-color: #8d8d8d;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 30px;
  position: absolute;
  margin-left: 5px;
  box-shadow: 0px 4px 8px #8d8d8d !important;
}

.status-content-flex {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.status-content-fixed {
  width: 190px;
}

.status-content-restant {
  width: calc(100% - 190px);
}

.status-order-background-color-Enviada {
  background-color: #79c6ff;
}

.status-order-background-color-Borrador {
  background-color: #7e7e7e;
}

.status-order-background-color-Aprobada,
.status-order-background-color-Recibida {
  background-color: #97e6b6;
}

.status-order-background-color-Correcciones {
  background-color: #ff7d7d;
}

.status-order-background-color-Pagada {
  background-color: #ffce00;
}

.status-order-background-color-Cancelada {
  background-color: #ff7d7d;
}
.status-order-background-color-RecibidoParcial {
  background-color: #7c7c7c;
}

.button-eliminar-imagen {
  width: 160px;
  height: 36px;
  background-color: #ff5757 !important;
  cursor: pointer;
  color: #fff;
  padding: 5px 25px !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}

.button-wrapper {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper span.label {
  width: 160px;
  position: relative;
  z-index: 0;
  line-height: 26px;
  display: inline-block;
  background: #ffce00;
  cursor: pointer;
  color: #4e4000;
  padding: 5px 25px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
}

#assetsFieldHandle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.container-dashed-image {
  border: 2px dashed #e0e0e0;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-details-odc {
  color: #b7b7b7;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  font-weight: 400;
}

.title-details-odc {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.input-table-materiales {
  background-color: #fafafa;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 5px;
}

.input-table-materiales:focus {
  outline: 0;
}
input {
  font-family: "Poppins", sans-serif;
}
</style>