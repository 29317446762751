<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogEnviada"
      width="1100"
      style="z-index: 1"
    >
      <!-- Start card borrador -->
      <v-card style="border-radius: 10px; padding: 20px 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <!-- title content -->
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Detalle de orden de compra
                <span
                  style="margin-top: 12px; margin-left: 15px"
                  :class="`status-order-span status-order-background-color-${statusOrder}`"
                  >{{ statusOrder }}</span
                >
              </p>
            </v-col>
          </v-row>
          <hr style="height: 1px; background-color: #ffce00; border: none" />
          <!-- content -->
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <!-- start item id -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">ID</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ idOrder }}
                    </p>
                  </div>
                </div>
                <!-- end item id -->
                <!-- start item proyecto -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Proyecto</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ proyecto }}
                    </p>
                  </div>
                </div>
                <!-- end item proyecto -->

                <!-- start item categorias -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Categoría</p>
                  </div>
                  <div class="status-content-restant">
                    <v-select
                      v-model="categoria"
                      class="text-Poppins"
                      item-text="sCategoryName"
                      item-value="sCategoryId"
                      :items="itemsCategory"
                      label="Selecciona la categoría"
                      style="margin-bottom: -10px"
                      @change="changeCategory"
                    ></v-select>
                    <p class="text-details-odc">
                      <!-- {{categoria}} -->
                    </p>
                  </div>
                </div>
                <!-- end item categorias -->

                <!-- start item proveedor -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Proveedor</p>
                  </div>
                  <div class="status-content-restant">
                    <p class="text-details-odc">
                      {{ proveedor }}
                    </p>
                  </div>
                </div>
                <!-- end item proveedor -->
                <!-- start item metodo de pago -->
                <div class="status-content-flex">
                  <div class="status-content-fixed">
                    <p class="title-details-odc">Método de pago</p>
                  </div>
                  <div class="status-content-restant">
                    <v-select
                      v-model="cmdMetodoPago"
                      class="text-Poppins"
                      item-text="sName"
                      item-value="sPaymentMethodId"
                      :items="itemsMetodoPago"
                      label="Selecciona el método de pago"
                      style="margin-bottom: -10px"
                      @change="changePaymentMethod"
                    ></v-select>
                    <p class="text-details-odc">
                      <!-- {{metodoPago}} -->
                    </p>
                  </div>
                </div>
                <!-- end item metodo de pago -->
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <!-- start item fecha de creacion -->
              <div class="status-content-flex">
                <div class="status-content-fixed">
                  <p class="title-details-odc">Fecha de creación</p>
                </div>
                <div class="status-content-restant">
                  <p class="text-details-odc">
                    {{ fechaCreacionOrder }}
                  </p>
                </div>
              </div>
              <!-- end item fecha de creacion -->
              <!-- start item fecha de creacion -->
              <div class="status-content-flex">
                <div class="status-content-fixed">
                  <p class="title-details-odc">Notas al proveedor:</p>
                </div>
                <div class="status-content-restant">
                  <p class="text-details-odc">
                    {{
                      comentarios.trim() === ""
                        ? '"No hay notas adicionales para esta orden de compra."'
                        : comentarios
                    }}
                  </p>
                </div>
              </div>
              <!-- end item fecha de creacion -->
              <!-- start item fecha de creacion -->
              <div class="status-content-flex">
                <div class="status-content-fixed">
                  <p class="title-details-odc">
                    Notas internas
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                        <v-icon
                          style="
                            cursor: pointer;
                            font-size: 15px;
                            margin-bottom: 10px;
                            margin-left: 0px !important;
                          "
                          color="blue"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle</v-icon
                        >

                        <!-- </v-btn> -->
                      </template>
                      <span
                        >Estas notas no serán visibles por los proveedores ni
                        estarán presentes en la cotización generada.
                      </span>
                      <br />
                      <span
                        >Solo modificables en: Borrador y correcciones.</span
                      >
                    </v-tooltip>
                    :
                  </p>
                </div>
                <div class="status-content-restant">
                  <p class="text-details-odc">
                    {{
                      comentariosInternos.trim() === ""
                        ? '"No hay notas internas definidas para esta orden de compra."'
                        : comentariosInternos
                    }}
                  </p>
                </div>
              </div>
              <!-- end item fecha de creacion -->
            </v-col>
          </v-row>
          <!-- start table materials -->
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <!-- Start simple table -->
                <v-card style="border-radius: 20px; padding: 20px !important">
                  <v-simple-table fixed-header height="200px">
                    <template>
                      <thead v-if="!bLoading">
                        <tr>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Concepto
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Cantidad
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Unidad de medida
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Precio unitario
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Importe
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="!bLoading">
                        <tr
                          v-for="(item, index) in itemsRequestPerMaterial"
                          :key="index"
                        >
                          <td class="text-left">
                            {{ item.sMaterialName }}
                            <!-- <input
                              v-model="item.sMaterialName"
                              type="text"
                              :key="index"
                              class="input-table-materiales"
                            /> -->
                          </td>
                          <td class="text-left">
                            {{ item.dQuantity }}
                            <!-- <input
                              v-model="item.dQuantity"
                              type="number"
                              :key="index"
                              class="input-table-materiales"
                              min="0"
                            /> -->
                          </td>
                          <td class="text-left">{{ item.sMeasureUnitName }}</td>
                          <td class="text-left">
                            ${{ mask(item.dUnitPrice) }} MXN
                            <!-- <input
                              v-model="item.dUnitPrice"
                              type="number"
                              :key="index"
                              class="input-table-materiales"
                              min="0"
                            /> -->
                          </td>
                          <td class="text-left">
                            ${{ sumatoriaImporte(item) }} MXN
                          </td>
                        </tr>
                      </tbody>
                      <div v-show="bLoading">
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                        <div class="content-text-loading">
                          <p class="text-loading">
                            Cargando... Espere por favor
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-simple-table>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      padding-right: 20px;
                      margin-top: 10px;
                    "
                  >
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                      "
                    >
                      SUBTOTAL
                      <span style="color: #63e3b2; margin-left: 10px"
                        >${{ sumatoriaMateriales() }} MXN</span
                      >
                    </p>
                  </div>
                </v-card>
                <!-- End simple table -->
              </div>
            </v-col>
          </v-row>
          <br />
          <!-- end table materials -->
          <!-- buttons section -->
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-btn
                  x-large
                  color="black"
                  width="175"
                  outlined
                  class="text-Poppins"
                  @click="close"
                  style="
                    height: 50px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                    margin-left: 25px;
                  "
                  >Cerrar
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="text-right">
              <div>
                <v-btn
                  x-large
                  color="primary"
                  class="text-Poppins"
                  style="
                    width: 210px;
                    height: 50px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                  "
                  :disabled="disableBtnDownloadDoc"
                  @click="downloadPdf"
                  :loading="loadingButtonCotizacion"
                  >Descargar cotización
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- End card borrador -->
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DetailsOrders",
  props: {
    openDialogEnviada: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      itemsOrders: [],
      itemsProyecto: [],
      itemsCategory: [],
      itemsMetodoPago: [],
      itemsProveedores: [],
      itemsRequestPerMaterial: [],
      statusOrder: "",
      idOrder: "",
      fechaCreacionOrder: "",
      proyectoId: "",
      proyecto: "",
      categoria: "",
      proveedor: "",
      cmdMetodoPago: "",
      metodoPago: "",
      sPaymentMethodId: "",
      comentarios: "",
      comentariosInternos: "",
      subtotal: 0,
      document: "",
      loadingButtonCotizacion: false,
      disableBtnDownloadDoc: false,
      bLoading: true,
    };
  },
  methods: {
    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    downloadPdf() {
      this.loadingButtonCotizacion = true;

      db.get(
        `${uri}/api/v1/purchase_order/order/${this.itemDetails.sPurchaseOrderId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
        .then((resp) => {
          if (resp.data.order.sQuoteKey !== "Sin documento por el momento.") {
            this.loadingButtonCotizacion = false;
            window.open(resp.data.order.sQuoteKey, "_blank");
          } else {
            this.loadingButtonCotizacion = false;
            const alert = {
              color: "red",
              text: "No existe un documento asociado a esta orden de compra.",
            };
            this.$store.commit("toggle_alert", alert);
          }
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonCotizacion = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    close() {
      this.$emit("close");
    },
    allData() {
      this.getMaterialResquest();

      this.disableBtnDownloadDoc = this.itemDetails.sQuoteKey == "";
      this.statusOrder = this.itemDetails.PurchaseOrderStatusRecords.sName;
      this.itemsOrders = this.itemDetails;
      this.idOrder = this.itemDetails.sPurchaseOrderId;
      this.fechaCreacionOrder = this.itemDetails.tDate;
      this.proyectoId = this.itemDetails.sProjectId;
      this.proyecto = this.itemDetails.sProjectName;
      this.getCategoriesbyProyect();
      this.categoria = this.itemDetails.sCategoryId;
      this.proveedor = this.itemDetails.sSupplierName;
      this.getMetodoPago();
      this.metodoPago = this.itemDetails.sPaymentMethodId;
      this.comentarios = this.itemDetails.sComments;
      this.comentariosInternos = this.itemDetails.sPrivateComments;
      this.cmdMetodoPago = this.metodoPago;
    },
    getMaterialResquest() {
      this.bLoading = true;
      // get material per request
      db.get(
        `${uri}/api/v1/purchase_order/materials/${this.itemDetails.sPurchaseOrderId}/1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            // iPageNumber: "1",
            formatDate: true,
          },
        }
      )
        .then((resp) => {
          this.bLoading = false;
          this.itemsRequestPerMaterial = resp.data.OrderMaterials.map((e) => {
            return {
              ...e,
              dQuantity: Number(e.dQuantity),
            };
          });
          this.sumatoriaMateriales();
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerMaterial.length; i++) {
        const element = this.itemsRequestPerMaterial[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    sumatoriaImporte(e) {
      var sum = 0;
      sum = sum + Number(e.dQuantity) * Number(e.dUnitPrice);
      return this.mask(sum);
    },
    changeCategory() {
      const param = {
        sCategoryId: this.categoria,
        sPurchaseOrderId: this.itemDetails.sPurchaseOrderId,
      };
      db.patch(`${uri}/api/v1/purchase_order/category/`, param, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/json",
        },
      })
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changePaymentMethod(e) {
      var text = this.itemsMetodoPago.filter(
        (val) => val.sPaymentMethodId === e
      );
      this.sPaymentMethodId = text[0].sPaymentMethodId;
      const param = {
        sPaymentMethodId: this.sPaymentMethodId,
      };
      db.patch(
        `${uri}/api/v1/purchase_order/${this.itemDetails.sPurchaseOrderId}`,
        param,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/json",
          },
        }
      )
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.close();
          this.$store.commit("refresher", true);
          this.$store.commit("toggle_alert", alert);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getCategoriesbyProyect() {
      db.get(`${uri}/api/v1/projects/${this.proyectoId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsCategory = resp.data.project.Categories;
          if (this.itemDetails.sCategoryId == "N/A") {
            this.itemsCategory.unshift({
              sCategoryId: "N/A",
              sCategoryName: "Sin categorizar",
            });
          }
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  watch: {
    openDialogEnviada: function () {
      if (this.openDialogEnviada) {
        this.allData();
      }
    },
    closeDialogDetails: function () {
      if (!this.$store.state.refresh) {
        this.close();
      }
    },
  },
  computed: {
    closeDialogDetails() {
      return this.$store.state.refresh;
    },
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
  },
};
</script>

<style scoped>
.status-order-span {
  background-color: #8d8d8d;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 30px;
  position: absolute;
  margin-left: 5px;
  box-shadow: 0px 4px 8px #8d8d8d !important;
}

.status-content-flex {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.status-content-fixed {
  width: 190px;
}

.status-content-restant {
  width: calc(100% - 190px);
}

.status-order-background-color-Enviada {
  background-color: #79c6ff;
}

.status-order-background-color-Borrador {
  background-color: #7e7e7e;
}

.status-order-background-color-Aprobada,
.status-order-background-color-Recibida {
  background-color: #97e6b6;
}

.status-order-background-color-Correcciones {
  background-color: #ff7d7d;
}

.status-order-background-color-Pagada {
  background-color: #ffce00;
}

.status-order-background-color-Cancelada {
  background-color: #ff7d7d;
}

.button-eliminar-imagen {
  width: 160px;
  height: 36px;
  background-color: #ff5757 !important;
  cursor: pointer;
  color: #fff;
  padding: 5px 25px !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}

.button-wrapper {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper span.label {
  width: 160px;
  position: relative;
  z-index: 0;
  line-height: 26px;
  display: inline-block;
  background: #ffce00;
  cursor: pointer;
  color: #4e4000;
  padding: 5px 25px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
}

#assetsFieldHandle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.container-dashed-image {
  border: 2px dashed #e0e0e0;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-details-odc {
  color: #b7b7b7;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  font-weight: 400;
}

.title-details-odc {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.input-table-materiales {
  background-color: #fafafa;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 5px;
}

.input-table-materiales:focus {
  outline: 0;
}
</style>